@import '../../../styles/variables';

.blogDetails {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  .container {
    padding: 0;
  }
  .blog-article {
    display: flex;
    flex-grow: 1;
    .container {
      padding: 5rem 3rem;
      flex-grow: 1;
      background-color: #fff;
    }
    &-top {
      display: flex;
      flex-direction: column;
      padding: 0 3rem;
      gap: 1rem;
      .header {
        display: flex;
        justify-content: space-between;
        .backToBlog {
          color: #73cac2;
          cursor: pointer;
          transition: color 0.4s $cubic-out;
          &:hover {
            color: #2f455c;
          }
        }
      }
      .title {
        font-size: 2rem;
        color: #73cac2;
        font-weight: 700;
        line-height: 2.1rem;
      }
      .details {
        display: flex;
        justify-content: space-between;
        .articleShare {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    &-image {
      max-height: 50vw;
      position: relative;
      margin-top: 2rem;
      margin-bottom: 3rem;
      min-height: 30rem;
      height: 100%;
      padding-top: 80%;
      animation-duration: 2s;
      img {
        opacity: 1;
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
    .text {
      padding: 0 3rem;
      font-size: 1.3rem;
      color: #7c7777;
      font-weight: 300;
      line-height: 1.8rem;
      h2 {
        color: #2cb7b0;
        font-weight: 600;
        font-size: 1.6rem;
        padding-block: 20px;
      }
      a {
        color: #2cb7b0;
        transition: color 0.5s $cubic-out;
        &:hover {
          color: #514948;
        }
      }
    }
  }
}
