@use 'variables' as *;

/**
  Container
  ===================================================================
*/
.wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  max-width: $layout-width;
  padding: 0 20px;
  margin: 0 auto;
  width: 100%;
}
.svg-defs {
  display: none;
}
strong {
  font-weight: $semibold;
}
.divider-bg {
  background: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.divider {
  width: 100%;
  background: $white;
  margin: 0 auto;
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  height: 42px;
  bottom: 0;
}

.divider:after,
.divider:before {
  content: '';
  width: 48.5%;
  position: absolute;
  bottom: 8px;
  top: 0;
  -moz-transform: rotate(-1deg);
  -ms-transform: rotate(-1deg);
  -o-transform: rotate(-1deg);
  -webkit-transform: rotate(-1deg);
  transform: rotate(-1deg);
  -moz-box-shadow: 0 15px 10px #777;
  -webkit-box-shadow: 0 15px 10px #777;
  box-shadow: 0 7px 30px rgba(0, 0, 0, 0.15);
  z-index: -1;
}
.shadow::after {
  left: 8px;
}
.shadow::before {
  right: 8px;
  -moz-transform: rotate(1deg);
  -ms-transform: rotate(1deg);
  -o-transform: rotate(1deg);
  -webkit-transform: rotate(1deg);
  transform: rotate(1deg);
  left: auto;
}

.main {
  padding-top: 6.25rem;
  sup {
    line-height: 0.8;
    font-size: 1.1rem;
  }
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 0.4s $cubic-out forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
}
