@import '../../../../styles/variables';

.faq-list {
  max-width: 850px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  &-item {
    &-title {
      color: #2cb7b0;
      cursor: pointer;
      width: 100%;
      border-bottom: 1px solid #a3a09d;
      text-align: left;
      outline: 0;
      font-weight: 600;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      color: #2cb7b0;
      cursor: pointer;
      line-height: 1.4rem;
      padding: 5px 0;

      .qa {
        line-height: 1.4rem;
        padding: 10px 0;
        width: calc(100% - 25px);
      }
      .qa-arrow {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='11' viewBox='0 0 25 16'%3E%3Cpath style='fill:%232cb7b0' d='M12.5,15.8c-0.6,0-1.1-0.2-1.5-0.7L0.5,3.3c-0.7-0.8-0.7-2.1,0.2-2.8c0.8-0.7,2.1-0.7,2.8,0.2l9,10.1 l9-10.1c0.7-0.8,2-0.9,2.8-0.2c0.8,0.7,0.9,2,0.2,2.8L14,15.1C13.6,15.5,13.1,15.8,12.5,15.8L12.5,15.8z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        width: 20px;
        height: 11px;
        transition: transform 0.5s $cubic-out;
      }
    }
    &-text {
      max-height: 0;
      height: 0;
      overflow: hidden;
      transition: all 1s $cubic-out;
      color: #2f455c;
      font-size: 1.1rem;
      font-weight: 300;
      padding: 0;
      p {
        line-height: 1.3;
      }
    }
    &.isActive {
      .faq-list-item-text {
        max-height: 200px;
        height: 100%;
        padding: 20px 0;
      }
      .qa-arrow {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='11' viewBox='0 0 25 16'%3E%3Cpath style='fill:%232f455c' d='M12.5,15.8c-0.6,0-1.1-0.2-1.5-0.7L0.5,3.3c-0.7-0.8-0.7-2.1,0.2-2.8c0.8-0.7,2.1-0.7,2.8,0.2l9,10.1 l9-10.1c0.7-0.8,2-0.9,2.8-0.2c0.8,0.7,0.9,2,0.2,2.8L14,15.1C13.6,15.5,13.1,15.8,12.5,15.8L12.5,15.8z'%3E%3C/path%3E%3C/svg%3E");
        transform: rotate(180deg);
      }
    }
  }
}
