@import '../../../styles/variables';

.blog {
  background-color: #f9f7f7;
  flex-grow: 1;
  padding-bottom: 4rem;
  .container {
    flex-direction: column;
    max-width: 85rem;
    @media #{$max-s} {
      padding: 0;
    }
  }
  &-top {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4rem 0 2rem;

    @media #{$max-s} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  &-headerTitle {
    font-size: 1.2rem;
    font-weight: 900;
    color: #a3a09d;
    text-transform: uppercase;
  }
  &-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #73cac2;
    padding: 3.125rem 0 1.875rem;
    &-title {
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      padding-bottom: 10px;
      text-align: center;
    }
    &-text {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 500;
      padding-bottom: 10px;
      text-align: center;
      line-height: 1.2;
    }
  }
  &-reset {
    color: #73cac2;
    cursor: pointer;
    transition: color 0.5s $cubic-out;
    &:hover {
      color: #2f455c;
    }
  }
  &-articles {
    margin-bottom: 2rem;
  }
  &-listHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 0 2rem;

    @media #{$max-s} {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  &-list {
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(300px, calc(96% / 3)));

    grid-template-columns: 32% 32% 32%;
    gap: 2%;
    @media #{$max-m} {
      grid-template-columns: 1fr 1fr;
    }
    @media #{$max-s} {
      grid-template-columns: 1fr;
    }
  }
}
