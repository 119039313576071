@import '../../styles/variables';
.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: all 0.3s ease;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  @media #{$max-m} {
    overflow: scroll;
  }
  @media #{$max-s} {
    padding: 0rem;
  }
  .contact {
    background-color: #f9f7f7;
    padding: 3rem;
    @media #{$max-s} {
      padding: 1rem;
    }
  }
  .modalContainer {
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 2;
    background: #f9f7f7;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    max-width: 1700px;
    padding: 0 20px;
    @media #{$max-s} {
      padding: 0;
    }
  }

  &-bg {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
  &-container {
    position: relative;
    width: 100%;
    max-width: 1280px;
  }
  &-close {
    position: absolute;
    right: 1.5rem;
    top: 1.1rem;
    background: none;
    border: 0px;
    font-weight: bold;
    cursor: pointer;
    z-index: 2;
    width: 2.5rem;
    height: 2.5rem;
    transition: transform 0.2s $cubic;
    &:hover {
      transform: rotate(90deg);
      span {
        background: $secondary-7;
      }
    }
    span {
      width: 2.5rem;
      background: $primary-2;
      height: 2px;
      display: block;
      position: absolute;
      transition: all 0.3s $cubic-out;
    }
    span:nth-child(1) {
      transform: rotate(-45deg);
    }
    span:nth-child(2) {
      transform: rotate(45deg);
    }
  }
  .opened-modal {
    @media #{$max-m} {
      .modal {
        top: 0;
        overflow: scroll;
        &-container {
          top: 0;
        }
        &-bg {
          position: absolute;
          top: 0;
        }
      }
    }
  }
  .contact {
    background-color: #f9f7f7;
    padding: 3rem;
    @media #{$max-m} {
      min-height: 100vh;
    }
    @media #{$max-s} {
      padding: 3rem 0;
    }
  }

  .modalContainer {
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 2;
    background: #f9f7f7;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .modalContainer::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #f9f7f7;
    z-index: -1;
  }

  .success-message {
    padding: 0 4rem 4rem;
    text-align: center;
    font-size: 1.3rem;
    color: $primary-2;
  }

  .main-title {
    color: #2f455c;
    font-size: 2.5rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 1.5rem;
    @media #{$max-s} {
      font-size: 1.8rem;
      padding-top: 2rem;
    }
  }

  .title {
    color: #2cb7b0;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    padding-bottom: 4rem;
    @media #{$max-s} {
      font-size: 1.8rem;
      padding-bottom: 2rem;
    }
  }

  .text {
    font-size: 2rem;
    font-weight: 300;
  }

  .contact-frm {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 75rem;
    width: 100%;
  }

  .frm-column {
    display: flex;
    flex-direction: column;
    flex: 0 1 27rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .frm-title {
    color: #2f455c;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 3rem;
  }

  .frm-row {
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
    flex-grow: 1;
    position: relative;
  }

  .error-message {
    bottom: 0.8rem;
    font-size: 0.8rem;
    color: red;
  }

  label {
    color: #2f455c;
    font-size: 0.9rem;
    font-weight: 300;
    padding-bottom: 1rem;
  }

  input {
    border-bottom: 1px solid #2f455c;
  }

  textarea {
    border: 1px solid #2f455c;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    margin-top: 2rem;
    min-height: 10rem;
    padding: 1rem;
  }

  .divider {
    background: #f9f7f7;
    z-index: -1;
    height: 20px;
  }

  .frm-button a {
    display: inline-block;
    margin: 2.5rem 0;
    font-size: 1.1rem;
    font-weight: 600;
  }

  .errors {
    width: 100%;
    text-align: center;
  }

  .errors p {
    color: red;
    line-height: 1.2;
    font-size: 0.9rem;
  }

  .hide {
    display: none !important;
  }

  .show {
    display: block !important;
  }

  input.error-field {
    border-bottom-color: red !important;
  }

  textarea.error-field {
    border-color: red !important;
  }
}
