@use 'variables' as *;

/**
  Print
  ===================================================================
*/
@media print {
  body {
    background-color: $white !important;
    color: $black !important;
  }

  #layout-grid {
    min-height: 0;
  }
}
