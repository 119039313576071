.newsletter {
  --max-margin: 30;
  --min-margin: 20;
  padding-left: var(--responsive-margin);
}

.newsletter-form h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  line-height: normal;
  margin-bottom: 10px;
}

.newsletter h5 {
  font-size: 0.9rem;
  padding: 0;
  margin-bottom: 40px;
  color: #fff;
}

.newsletter form {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: 0 0;
  color: #2cb7b0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #e5e5e5;
  transition: 0.2s ease-in-out;
  transition-property: all;
  transition-property: color, background-color, border;
  position: relative;
}

.newsletter input {
  border: none;
  background: 0 0;
  font-size: 0.9rem;
  padding: 0 0;
  height: 40px;
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.newsletter input:focus {
  color: #fff;
  background: 0 0;
  outline: 0;
}

.newsletter input::placeholder {
  color: #2cb7b0;
}

.newsletter button {
  color: #fff;
  background: #2cb7b0;
  border: 1px solid #2cb7b0;
  padding: 5px 15px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 700;
  height: min-content;
  cursor: pointer;
  width: 100%;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}

.newsletter button:hover {
  background: 0 0;
  color: #2cb7b0;
}

.newsletter-social {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  &-list {
    display: flex;
  }
}

.newsletter-social h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  line-height: normal;
  margin-bottom: 20px;
}

.newsletter-social a {
  padding-right: 20px;
}

.newsletter-social a svg path {
  fill: #fff;
}

.newsletter-social a:hover svg path {
  fill: #2cb7b0;
}
.error-message {
  position: absolute;
  bottom: -30px;
}
