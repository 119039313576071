@use 'variables' as *;

.btn {
  border-radius: 5px;
  text-align: center;
  font-weight: $bold;
  padding: 0.875rem 1.875rem;
  text-decoration: none;
  font-size: $fs-3;
  transition: all 0.3s $cubic;
}
.btn-primary {
  border: 1px solid $secondary-1;
  background-color: $white;
  color: $secondary-1;
  &:hover {
    background-color: $secondary-1;
    color: $white;
  }
}

.btn-secondary {
  border: 1px solid $primary-3;
  background-color: $primary-3;
  color: $white;
  &:hover {
    background-color: $white;
    color: $primary-3;
  }
}

.btn-2 {
  border: 1px solid $primary-3;
  background-color: $white;
  color: $primary-3;
  &:hover {
    background-color: $primary-3;
    color: $white;
  }
}

.gm-btn {
  transition: background-color 150ms linear, color 150ms linear;
}

#button-confirm,
.gm-btn {
  --max-font: 21;
  --min-font: 17;
  font-size: var(--responsive-font-size);
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
  padding: 15px 33px;
  text-decoration: none;
  width: max-content;
  display: block;
  cursor: pointer;
}

.btn-notext {
  display: inline-block;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  content: ' ';
  margin: 0 auto !important;
  border-radius: 10px;
  width: 37px;
  height: 35px;
  padding: 0;
}

#button-confirm,
.gm-btn.gm-btn-primary,
.gm-btn.gm-btn-secondary:hover {
  background-color: #73cac2;
  border: 1px solid #73cac2;
  color: #fff;
}

.gm-btn.gm-btn-primary:hover,
.gm-btn.gm-btn-secondary {
  background-color: #fff;
  border: 1px solid #2cb7b0;
  color: #2cb7b0;
}

#button-confirm,
.gm-btn.gm-btn-01 {
  background-color: #1d8483;
  border: 1px solid #1d8483;
  color: #fff;
}

.gm-btn.gm-btn-01:hover,
.gm-btn.gm-btn-02 {
  background-color: #73cac2;
  border: 1px solid #73cac2;
  color: #2f455c;
}

.gm-btn.gm-btn-03 {
  background-color: #2f455c;
  border: 1px solid #2f455c;
  color: #fff;
}

.gm-btn.gm-btn-03:hover {
  background-color: #fff;
  color: #2f455c;
}

.gm-btn.gm-btn-04 {
  background-color: #fff;
  border: 1px solid #1d8483;
  color: #1d8483;
}

.btn-notext.gm-btn-05 {
  border: 5px solid #d9d7d4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22.3' height='19.1' viewBox='0 0 22.3 19.1'%3E%3Cpath style='fill:%2373CAC2;' d='M22,8.7L12.6,0L11,1.7l7.1,6.6H0v2.4h18.1L11,17.3l1.6,1.7l9.3-8.7c0.2-0.2,0.4-0.5,0.4-0.9 S22.2,8.9,22,8.7z'/%3E%3C/svg%3E%0A");
}

.gm-btn-06 {
  border: 5px solid #d9d7d4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22.3' height='19.1' viewBox='0 0 22.3 19.1'%3E%3Cpath style='fill:%232f455c;' d='M22,8.7L12.6,0L11,1.7l7.1,6.6H0v2.4h18.1L11,17.3l1.6,1.7l9.3-8.7c0.2-0.2,0.4-0.5,0.4-0.9 S22.2,8.9,22,8.7z'/%3E%3C/svg%3E%0A");
}

.gm-btn-07 {
  background-color: transparent;
  border: 5px solid rgba(255, 255, 255, 0.7);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22.3' height='19.1' viewBox='0 0 22.3 19.1'%3E%3Cpath style='fill:%23ffffff;' d='M22,8.7L12.6,0L11,1.7l7.1,6.6H0v2.4h18.1L11,17.3l1.6,1.7l9.3-8.7c0.2-0.2,0.4-0.5,0.4-0.9 S22.2,8.9,22,8.7z'/%3E%3C/svg%3E%0A");
}

.gm-btn.gm-btn-08 {
  font-size: 0.95rem;
  border-radius: 3px;
  background-color: transparent;
  border: 3px solid #d7d4d0;
  color: #2f455c;
  padding: 10px 15px;
}

.gm-btn.gm-btn-08:hover {
  background-color: #73cac2;
  color: #fff;
}

.gm-btn.gm-btn-08 svg {
  margin: 0 0 0 9px;
}

.gm-btn.gm-btn-08 svg path {
  fill: #74cac2;
}

.gm-btn.gm-btn-08:hover svg path {
  fill: #fff;
}

.gm-btn-primary.gm-btn-hero {
  font-weight: 600;
  font-size: 1.1rem;
  background: #2cb7b0;
  border-color: #2cb7b0;
  padding: 1rem 5rem;
  margin-bottom: 2.5rem;
  letter-spacing: 0.2rem;
  margin-top: 2rem;
  position: relative;
}

.gm-btn.gm-btn-primary.gm-btn-learnmore {
  background: #5e6362;
  border-color: #5e6362;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.1rem;
  padding: 1rem 4rem;
  letter-spacing: 0.2rem;
  margin-top: 54px;
  float: left;
}
