@import '../../styles/variables';

footer {
  .footer-row-1 {
    padding: 50px 0;
    background: $primary-3;
    .container {
      max-width: $layout2-width;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      color: $secondary-5;
      font-size: $fs-1;
      line-height: 1.4;
      .footer-logo {
        fill: $white;
        padding-bottom: 20px;
        display: block;
        transition: all 0.2s $cubic;
        &:hover {
          fill: $secondary-5;
        }
      }
      .footer-links {
        padding-top: 78px;
        display: flex;
        flex-direction: column;
        .footer-links-item {
          transition: color 0.2s $cubic;
          &:hover {
            color: $white;
          }
        }
      }
      .newsletter {
        max-width: 400px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4 {
          font-size: $fs-3;
          font-weight: $bold;
          color: $white;
          line-height: normal;
          margin: 10px 2;
          line-height: 1.4;
        }
        h5 {
          font-size: $fs-1;
          font-weight: $regular;
          color: $white;
          line-height: 1.4;
          margin-bottom: 1rem;
        }
        form {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          background: 0 0;
          color: $primary-2;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: 1px solid $white;
          transition: all 0.2s $cubic;
          transition-property: color, background-color, border;
          input {
            border: none;
            background: 0 0;
            font-size: 0.9rem;
            padding: 0 0;
            height: 40px;
            max-width: 100%;
            width: 100%;
            vertical-align: middle;
            display: inline-block;
          }
          .add-newsletter {
            color: #fff;
            background: #2cb7b0;
            border: 1px solid #2cb7b0;
            padding: 5px 15px;
            border-radius: 4px;
            font-size: 0.9rem;
            font-weight: 700;
            height: min-content;
            cursor: pointer;
            width: 100%;
          }
        }
        .newsletter-social {
          h4 {
            line-height: 1.4;
            margin-bottom: 1rem;
          }
          .social-links {
            display: flex;
            column-gap: 1rem;
            svg {
              fill: $white;
            }
          }
        }
      }
    }
  }
  .footer-row-2 {
    background: $primary-2;
    .container {
      max-width: $layout2-width;
      justify-content: center;
      font-size: $fs-0;
      color: $white;
      padding: 1rem 0;
    }
  }
}
