@import '../../../styles/variables';

.cold-chain-hero {
  background-color: $secondary-6;
  padding: 0;
  text-align: center;
  .cold-chain-hero-title {
    font-size: $fs-7;
    font-weight: $extrabold;
    color: $white;
    line-height: 1.4;
    background: $primary-3;
    width: 100%;
    padding: 1.2rem 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.125rem;
    max-width: 1000px;
    .cold-chain-hero-subtitle {
      font-size: $fs-6;
      font-weight: $regular;
      color: $primary-3;
      line-height: 1.3;
      max-width: 43.75rem;
    }
    .cold-chain-hero-image {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 2rem;
      animation-duration: 3s;
      max-height: 35.625rem;
      @media #{$max-m} {
      }
      img {
        max-width: 62.5rem;
        width: 100%;
      }
    }
    .cold-chain-hero-video {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      margin: 2rem 0;
      .yt-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .cold-chain-hero-text {
      font-size: $fs-6;
      font-weight: $light;
      color: $primary-3;
      line-height: 1.4;
      max-width: 68rem;
    }
    .cold-chain-hero-button {
      div {
        display: block;
        margin: 2.5rem 0;
        font-size: $fs-4;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
}
.cold-chain-teasers {
  padding: 9.375rem 0;
  @media #{$max-s} {
    padding: 3rem 0;
  }

  .container {
    max-width: $layout-width;
    display: flex;
    flex-direction: column;
    .teaser-boxes {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      @media #{$max-xs} {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
      .teaser-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        @media #{$max-s} {
          padding: 0;
        }
        &:nth-child(2) {
          border-left: 1px solid $primary-1;
          border-right: 1px solid $primary-1;
          @media #{$max-l} {
            border-right: none;
          }
          @media #{$max-s} {
            border-left: none;
          }
        }

        .teaser-title {
          color: $primary-3;
          font-weight: $semibold;
          font-size: $fs-34;
          line-height: 1.4;
          padding-bottom: 1rem;
        }
        .teaser-text {
          font-size: $fs-1;
          font-weight: $light;
          line-height: 1.4;
          flex-grow: 1;
          p {
            padding-bottom: 0.5rem;
          }
          h4 {
            font-weight: $bold;
            color: $primary-2;
            font-size: $fs-2;
            padding: 0.5rem 0;
          }
        }
        .teaser-button {
          .btn {
            display: flex;
            width: fit-content;

            margin-top: 2.5rem;
          }
        }
      }
    }
  }
}
