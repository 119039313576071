@import '../../../styles/variables';
.contactPage {
  background-color: #f7f7f7;
  iframe {
    animation-duration: 2s;
  }
  .info-main-container {
    margin: 0 auto;
    width: 70vw;
    padding-top: 70px;
    @media #{$max-s} {
      width: 100vw;
      padding: 1.25rem 1rem;
    }
  }

  .info-title {
    text-align: center;
    background: #73cac2;
    padding: 17px;
    font-size: 1.7rem;
    font-weight: 600;
    color: #fff;
  }
  .contact-fields {
    margin-top: 0;
  }
  .contact-info-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    @media #{$max-s} {
      width: 100%;
    }
  }
  .main-info {
    background: #fff;
    width: 100%;
    margin: 0 auto;
    padding: 45px 0 35px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    @media #{$max-s} {
      padding: 1rem 0 35px;
    }
  }

  .contact-info-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    @media #{$max-m} {
      flex-direction: column;
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;
      align-items: center;
      justify-content: center;
    }
    @media #{$max-s} {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .contact-image {
    margin-right: 50px;
    @media #{$max-m} {
      margin-right: 0;
    }
    iFrame {
      @media #{$max-s} {
        width: 100%;
      }
    }
  }

  .contact-details {
    color: #7c7777;
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 300;
  }

  .contact-details h3 {
    width: 100%;
    color: #73cac2;
    font-weight: 800;
    font-size: 1.7rem;
    padding-bottom: 40px;
  }

  .contact-infos {
    display: flex;
    flex-wrap: nowrap;
    @media #{$max-s} {
      flex-direction: column;
      width: 100%;
    }
  }

  .contact-info-left {
    a {
      color: #73cac2;
      transition: color 0.4s $cubic-out;
      &:hover {
        color: #7c7777;
      }
    }
    width: 50%;
    @media #{$max-s} {
      width: 100%;
    }
  }

  .contact-email strong,
  .contact-info-right a {
    color: #2cb7b0;
  }

  .contact-email a:hover {
    color: #2f455c;
  }

  .contact-info-right {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 50%;
    @media #{$max-s} {
      width: 100%;
    }
  }

  .contact-social {
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 10px;
  }

  .forgot {
    text-align: center;
    padding: 20px 0;
    width: 100%;
    display: block;
  }

  .info-form form {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 0 55px;
  }

  .form-header {
    margin-bottom: 20px;
  }

  .info-form h3,
  .info-form h4 {
    text-align: center;
    padding-bottom: 10px;
  }

  .info-form h3 {
    color: #5b514b;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .info-form h4 {
    font-size: 1rem;
    font-weight: 300;
    color: #2cb7b0;
  }

  .info-form label {
    font-size: 0.9rem;
    font-weight: 600;
    color: #2cb7b0;
    text-transform: uppercase;
  }

  .info-form label span {
    font-weight: 300;
    font-size: 1.2rem;
    padding-left: 10px;
  }

  .info-form input,
  .info-form select,
  .info-form textarea {
    font-size: 1.3rem;
    font-weight: 300;
    color: #514948;
    border-bottom: 1px solid #d5d1cb;
    padding: 9px 0;
    border-top: none;
    border-left: none;
    border-right: none;
    background: 0 0;
    outline: 0;
    width: 100%;
  }

  .info-form select {
    margin-top: 10px;
    border: 1px solid #d5d1cb;
    padding-left: 20px;
  }

  .info-form input[type='radio'] {
    display: inline;
    width: 30px;
  }

  .orders a.add-btn {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .orders .add-btn span {
    display: inline;
    padding-left: 20px;
  }

  .contact-fields {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .col-50 {
    width: calc(50% - 40px);
    padding: 10px 20px;
    @media #{$max-s} {
      width: 100%;
      padding: 10px 10px;
    }
  }

  .col-100 {
    width: calc(100% - 40px);
    padding: 10px 20px;
    @media #{$max-s} {
      width: 100%;
      padding: 10px 10px;
    }
  }

  .info-form .buttons {
    margin: 0 auto;
  }

  .info-form input.gm-btn {
    border-radius: 5px;
    padding: 1rem 3rem;
    margin-bottom: 0;
  }

  .contact-success {
    padding: 75px 0;
    min-height: 420px;
  }

  .contact-social-list {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 10px;
    gap: 1rem;
    align-items: end;
    a {
      width: 2rem;
      height: 1.5rem;
      color: #514948;
      transition: color 0.4s $cubic-out;
      &:hover {
        color: #2cb7b0;
      }
      svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
      }
    }
  }

  .info-form {
    background: #f2efed;
  }

  h3 {
    padding-top: 60px;
  }
  .error-message {
    padding: 0.5rem 0;
    font-size: 0.9rem;
    color: red;
  }
}
