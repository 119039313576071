@import '../../../../styles/variables';

.articleTags {
  font-size: 0.8rem;
  color: #a3a09d;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  line-height: 1.4;
  @media #{$max-m} {
    font-size: 0.75rem;
  }
  @media #{$max-s} {
    font-size: 0.8rem;
  }
  &-item {
    transition: color 0.5s $cubic-out;
    &:hover {
      color: #2eb7b0;
    }
    &:not(:first-child)::before {
      content: '/';
      color: #a3a09d;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      cursor: default;
      @media #{$max-m} {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
      }
    }
  }
}
