@import '../../../../styles/variables';

.benefitsImage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 100vh;
  background-size: cover;
  background-position: center;
  @media #{$max-m} {
    justify-content: center;
    height: 500px;
  }
  .text {
    position: absolute;
    padding-right: 10%;
    z-index: 0;
    text-align: center;
    width: 40rem;
    color: #fff;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    @media #{$max-m} {
      padding-right: unset;
    }
    @media #{$max-s} {
      width: unset;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .linkToBenefits {
      color: $white;
      font-size: 1.8rem;
      font-weight: 800;
      cursor: pointer;
      svg {
        display: block;
        margin: 1rem auto 0;
        path {
          fill: #fff;
        }
      }
      &:hover {
        svg {
          path {
            fill: #2f455c;
          }
        }
        color: #2f455c;
      }
    }
  }
}
