@import '../../../../styles/variables';
.home-product {
  margin-top: 1.5625rem;
}

.home-product h2 {
  text-align: center;
  color: #2f455c;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 1.2;
  padding: 60px 20px 60px 20px;
}

.home-product-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  background: #f9f7f7;
  padding: 6% 0;
  width: 68%;
  flex-grow: 1;
  @media #{$max-m} {
    flex-direction: column;
    width: 100%;
  }
}

.home-product-cols {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
}

.home-product-side {
  width: 16%;
  transition: width 150ms linear;
  @media #{$max-l} {
    width: 8%;
  }
  @media #{$max-m} {
    width: unset;
  }
}

.home-product-image {
  width: 45%;
  margin-left: -15%;
  @media #{$max-m} {
    width: unset;
    margin: unset;
    padding: 0 90px;
  }
}

.home-product-texts {
  width: 54%;
  padding: 0 7%;
  @media #{$max-m} {
    width: unset;
    padding: 0 20px;
    margin: 0 40px;
  }
  @media #{$max-s} {
    padding: 20px 20px;
    margin: 0;
  }
}

.home-product-image svg {
  margin-left: -24%;
}

.home-product-texts h5 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1;
  color: #2cb7b0;
  font-weight: 700;
}

.home-product-texts h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #2f455c;
  font-weight: 600;
}

.home-product-texts p,
.home-product-texts ul li {
  line-height: 1.3;
  font-size: 1.1rem;
}

.home-product-texts p {
  margin-bottom: 2.2rem;
  color: #2f455c;
  font-weight: 400;
}

.home-product-texts ul {
  list-style: none;
  padding-left: 30px;
  --max-margin: 54;
  --min-margin: 50;
  margin-bottom: var(--responsive-margin);
}

.home-product-texts ul li {
  color: #2f455c;
  padding-left: 15px;
  padding-right: 30px;
  margin-bottom: 0.9rem;
  font-weight: 400;
}

.home-product-texts ul li::before {
  content: ' ';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.6 23' %3E%3Cpath style='fill:%232AB6AF;' d='M11.8,23C5.3,23,0,17.8,0,11.5S5.3,0,11.8,0c6.5,0,11.8,5.2,11.8,11.5S18.3,23,11.8,23z M11.8,2 C6.4,2,2,6.3,2,11.5S6.4,21,11.8,21c5.4,0,9.8-4.3,9.8-9.5S17.2,2,11.8,2z'/%3E%3Cpath style='fill:%232AB6AF;' d='M11.4,17.1c-0.2,0-0.4-0.1-0.6-0.2l-3.7-2.7c-0.4-0.3-0.5-1-0.2-1.4c0.3-0.4,1-0.5,1.4-0.2l2.8,2 l3.6-7.4c0.2-0.5,0.8-0.7,1.3-0.5c0.5,0.2,0.7,0.8,0.5,1.3l-4.2,8.5c-0.1,0.3-0.4,0.5-0.7,0.5C11.6,17.1,11.5,17.1,11.4,17.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  margin-left: -45px;
  position: absolute;
}
