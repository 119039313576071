$layout-width: 1700px;
$layout2-width: 1280px;
/**
  Media
  ===================================================================
*/
$width-xxs: 375;
$width-xs: 569;
$width-s: 768;
$width-m: 1180;
$width-l: 1280;
$width-ml: 1440;
$width-xl: 1600;
$width-xxl: 1920;

$xxs: 'only screen and (min-width: #{$width-xxs}px)';
$xs: 'only screen and (min-width: #{$width-xs}px)';
$s: 'only screen and (min-width: #{$width-s}px)';
$m: 'only screen and (min-width: #{$width-m}px)';
$l: 'only screen and (min-width: #{$width-l}px)';
$xl: 'only screen and (min-width: #{$width-xl}px)';
$xxl: 'only screen and (min-width: #{$width-xxl}px)';

$max-xxs: 'only screen and (max-width: #{$width-xxs - 1}px)';
$max-xs: 'only screen and (max-width: #{$width-xs - 1}px)';
$max-s: 'only screen and (max-width: #{$width-s}px)';
$max-m: 'only screen and (max-width: #{$width-m}px)';
$max-l: 'only screen and (max-width: #{$width-l }px)';
$max-ml: 'only screen and (max-width: #{$width-ml }px)';
$max-xl: 'only screen and (max-width: #{$width-xl - 1}px)';

$h-xs: 'only screen and (min-height: 640px)';
$h-s: 'only screen and (min-height: 860px)';

/**
  Typography
  ===================================================================
*/
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

$poppins: 'Poppins', sans-serif;
$default-font: $poppins;

/**
  Colors
  ===================================================================
*/
$black: #000;
$white: #fff;

$primary-1: #73cac2;
$primary-2: #2cb7b0;
$primary-3: #2f455c;
$primary-4: #82807e;
$primary-5: #f2efec;
$secondary-1: #178483;
$secondary-2: #f9f7f7;
$secondary-3: #a3a09d;
$secondary-4: #1e405e;
$secondary-5: #53e0d2;
$secondary-6: #cfcfd1;
$secondary-7: #7c7777;
$secondary-8: #c4e8e3;
$link-color: $primary-3;

/**
  Font-Sizes
  ===================================================================
*/
$base-size: 13px;

$fs-0: 0.81rem;
$fs-1: 1rem;
$fs-2: 1.1rem;
$fs-3: 1.2rem;
$fs-20: 1.25rem;
$fs-4: 1.32rem;
$fs-6: 2rem;
$fs-34: 2.12rem;
$fs-38: 2.38rem;
$fs-7: 3.25rem;
$fs-8: 2.5rem;

/**
  Animations
  ===================================================================
*/
$quad: cubic-bezier(0.455, 0.03, 0.515, 0.955); /* easeInOutQuad */
$quad-out: cubic-bezier(0.25, 0.46, 0.45, 0.94); /* easeOutQuad */
$cubic: cubic-bezier(0.645, 0.045, 0.355, 1); /* easeInOutCubic */
$cubic-out: cubic-bezier(0.215, 0.61, 0.355, 1); /* easeOutCubic */
$sine: cubic-bezier(0.445, 0.05, 0.55, 0.95); /* easeInOutSine */
$sine-out: cubic-bezier(0.39, 0.575, 0.565, 1); /* easeOutSine */

/**
  Export
  ===================================================================
*/
:export {
  //s: unquote($s);

  default-font: $default-font;

  black: $black;
  white: $white;
  primary-1: $primary-1;
  primary-2: $primary-2;
  primary-3: $primary-3;
  primary-4: $primary-4;
  secondary-1: $secondary-1;
  secondary-2: $secondary-2;
  secondary-3: $secondary-3;
  secondary-4: $secondary-4;

  quad: $quad;
  quad-out: $quad-out;
  cubic: $cubic;
  cubic-out: $cubic-out;
  sine: $sine;
  sine-out: $sine-out;
}
