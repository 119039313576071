@use 'variables' as *;

/**
  Typography
  ===================================================================
*/
html {
  font-family: $default-font;
  font-size: 14px;

  @media #{$xs} {
    font-size: 15px;
  }

  @media #{$s} {
    font-size: 15px;
  }

  @media #{$m} {
    font-size: 16px;
  }
}

strong {
  font-weight: $bold;
}

em {
  font-style: italic;
}
