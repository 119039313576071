@import '../../../styles/variables';

.about {
  background: $primary-3;
  padding: 3.125rem;
  @media #{$max-s} {
    padding: 0rem;
  }
  .container {
    max-width: $layout2-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    .about-title {
      font-size: $fs-8;
      font-weight: $bold;
      color: $white;
      line-height: 1.4;
      padding-block: 1rem;
      max-width: 56.25rem;
      width: 100%;
      sup {
        font-size: $fs-2;
      }
    }
    .about-text {
      max-width: 56.25rem;
      font-size: $fs-20;
      line-height: 1.3;
      font-weight: $light;
      color: $white;
      p {
        padding-bottom: 0.7rem;
      }
    }
  }
}

.aboutTeasers {
  padding: 3.125rem 0;
  .container {
    max-width: $layout2-width;
    display: flex;
    flex-direction: column;
    .teaser-boxes {
      display: flex;
      column-gap: 4rem;
      flex-wrap: wrap;
      .teaser-box {
        max-width: 36.25rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 2rem;
        .teaser-title {
          color: $primary-3;
          font-weight: $semibold;
          font-size: $fs-34;
          line-height: 1.4;
          padding-bottom: 1rem;
        }
        .teaser-text {
          font-size: $fs-1;
          font-weight: $light;
          line-height: 1.4;
          flex-grow: 1;
          p {
            padding-bottom: 0.5rem;
          }
          h4 {
            font-weight: $bold;
            color: $primary-2;
            font-size: $fs-2;
            padding: 0.5rem 0;
          }
        }
        .teaser-button {
          .btn {
            display: inline-block;
            margin-top: 2.5rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.team {
  padding: 3.125rem 0 5rem;
  .container {
    max-width: $layout2-width;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .team-title {
      font-size: 2.6rem;
      font-weight: 800;
      color: #73cac2;
      padding-bottom: 2rem;
      text-align: center;
    }
    .team-text {
      color: #353131;
      line-height: 2.5rem;
      font-size: 2rem;
      font-weight: 200;
      text-align: center;
    }
    .team-boxes {
      max-width: 1200px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 5rem;
      grid-gap: 4rem;
      .team-box {
        flex: 0 1 310px;
        .team-image {
          width: 310px;
          height: 310px;
          border-radius: 50%;
          border: 10px solid #73cac2;
          border-radius: 50%;
          padding: 6px;
          position: relative;
          div {
            background-size: contain;
            position: absolute;
            top: 10px;
            right: 10px;
            bottom: 10px;
            left: 10px;
            border-radius: 50%;
            transition: transform 0.8s $cubic-out;
            &:hover {
              transform: scale(1.075);
            }
          }
        }
        .team-email,
        .team-name,
        .team-position {
          color: #353131;
          font-weight: 700;
          text-align: center;
        }
        .team-name {
          font-size: 1.5rem;
          padding: 25px 0 10px;
        }
        .team-position {
          padding-bottom: 20px;
        }
        .team-email {
          font-weight: 400;
          font-size: 1.1rem;
          padding-bottom: 15px;
          a {
            color: #353131;
            transition: color 0.5s ease;
            &:hover {
              color: #73cac2;
            }
          }
        }
        .team-linkedin a {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 35' width='35' height='35'%3E%3Cpath style='fill:%230065A1;' d='M35,30.3c0,2.6-2.1,4.7-4.7,4.7H4.7C2.1,35,0,32.9,0,30.3V4.7C0,2.1,2.1,0,4.7,0h25.7 C32.9,0,35,2.1,35,4.7V30.3z'/%3E%3Crect x='8.2' y='11.9' style='fill:%23FFFFFF;' width='4.1' height='13.3'/%3E%3Cpath style='fill:%23FFFFFF;' d='M10.3,5.3c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4C9,10.1,7.9,9,7.9,7.7C7.9,6.4,9,5.3,10.3,5.3'/%3E%3Cpath style='fill:%23FFFFFF;' d='M15,11.9h4v1.8H19c0.6-1,1.9-2.2,3.9-2.2c4.2,0,5,2.8,5,6.3v7.3h-4.1v-6.5c0-1.5,0-3.5-2.2-3.5 c-2.2,0-2.5,1.7-2.5,3.4v6.6H15V11.9z'/%3E%3C/svg%3E");
          display: block;
          width: 35px;
          height: 35px;
          margin: 0 auto;
          &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 35 35' width='35' height='35'%3E%3Cpath style='fill:%2373cac2;' d='M35,30.3c0,2.6-2.1,4.7-4.7,4.7H4.7C2.1,35,0,32.9,0,30.3V4.7C0,2.1,2.1,0,4.7,0h25.7 C32.9,0,35,2.1,35,4.7V30.3z'/%3E%3Crect x='8.2' y='11.9' style='fill:%23DDF1EF;' width='4.1' height='13.3'/%3E%3Cpath style='fill:%23DDF1EF;' d='M10.3,5.3c1.3,0,2.4,1.1,2.4,2.4c0,1.3-1.1,2.4-2.4,2.4C9,10.1,7.9,9,7.9,7.7C7.9,6.4,9,5.3,10.3,5.3'/%3E%3Cpath style='fill:%23DDF1EF;' d='M15,11.9h4v1.8H19c0.6-1,1.9-2.2,3.9-2.2c4.2,0,5,2.8,5,6.3v7.3h-4.1v-6.5c0-1.5,0-3.5-2.2-3.5 c-2.2,0-2.5,1.7-2.5,3.4v6.6H15V11.9z'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
}
