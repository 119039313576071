@import '../../../../styles/variables';

.coolOneHero {
  padding: 2rem 5rem;
  overflow: hidden;
  position: relative;
  @media #{$max-m} {
    padding: 2rem 2rem;
  }
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='1100' height='1100' viewBox='0 0 100 100'%3E%3Cpath style='fill:%232CB7B0;' d='M100,50c0,27.6-22.4,50-50,50S0,77.6,0,50C0,22.4,22.4,0,50,0S100,22.4,100,50'/%3E%3C/svg%3E")
      no-repeat;
    width: 100rem;
    height: 100rem;
    top: 0;
    right: 0;
    z-index: 0;
    transform: translate(50%, -50%);
    background-size: cover;
    @media #{$max-m} {
      transform: translate(10%, -20%);
      width: 40rem;
      height: 50rem;
    }
  }
  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1440px;
    @media #{$max-s} {
      flex-direction: column;
    }
  }
  &-left {
    max-width: 36.25rem;
    z-index: 1;
    @media #{$max-s} {
      order: 2;
    }

    &-title {
      font-size: 3.5rem;
      color: #514948;
      font-weight: 800;
      padding-bottom: 1.5rem;
    }
    &-subtitle {
      color: #2cb7b0;
      font-size: 2.5rem;
      font-weight: 300;
      padding-bottom: 1.5rem;
      line-height: 2.8rem;
    }
    &-text {
      color: #514948;
      font-size: 1.6rem;
      font-weight: 500;
      padding-bottom: 2rem;
      line-height: 2.3rem;
    }
    &-text2 {
      color: #514948;
      font-size: 1.3rem;
      line-height: 1.4;
      font-weight: 500;
      padding-bottom: 2rem;
    }
    &-button {
      width: max-content;
    }
  }
  &-right {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    animation-duration: 2s;
    img {
      max-width: 800px;
      min-width: 500px;
      width: 100%;
    }
  }
}
