@import '../../../styles/variables';

.ourStory {
  .about-container {
    width: 53vw;
    @media #{$max-m} {
      width: 100%;
      padding: 0 1rem;
    }
  }
  .about-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #dcf2f0;
    padding: 70px 0 35px;

    h2 {
      color: #2cb7b0;
      font-size: 3.5rem;
      font-weight: 800;
      padding-bottom: 13px;
      text-align: center;
    }

    .subtitle {
      color: #514948;
      font-size: 2rem;
      font-weight: 200;
      padding-bottom: 20px;
      text-align: center;
      line-height: 2.5rem;
      font-weight: 300;
    }

    .journeyLink {
      color: #514948;
      font-size: 1.3rem;
      font-weight: 800;
      text-align: center;
      line-height: 2.2rem;
      cursor: pointer;

      &:hover {
        color: #2cb7b0;

        svg {
          path {
            fill: #514948;
          }
        }
      }

      svg {
        display: block;
        width: 27px;
        height: 18px;
        margin: 1rem auto 0;

        path {
          fill: #2cb7b0;
        }
      }
    }
  }

  .about-3 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .about-4 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .about-5 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .about-6 {
    width: 100%;
    display: flex;
    justify-content: center;

    .subtitle {
      color: #514948;
      font-size: 2rem;
      font-weight: 200;
      padding-bottom: 20px;
      text-align: center;
      line-height: 2.5rem;
    }
  }

  .about-2.section-four {
    background: #fff;
    padding: 50px 0 0;
  }
  .shadow-wrapper {
    max-width: 1615px;
    margin: 0 auto 60px auto;
    position: relative;
    z-index: 0;
  }
  .divider {
    width: 100%;
    background: #fff;
    margin: 0 auto;
  }
  .divider:after,
  .divider:before {
    content: '';
    width: 48.5%;
    position: absolute;
    bottom: 8px;
    top: 0;
    transform: rotate(-1deg);
    box-shadow: 0 7px 30px rgb(0 0 0 / 45%);
    z-index: -1;
  }
  .about-2 {
    position: relative;
    background: #fff;
    padding: 50px 0 0;
    margin-bottom: 50px;
    .containter-four {
      z-index: 1;
      position: relative;
      background: #f9f7f7;
      max-width: 1615px;
      margin: 0 auto;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      border: 1px solid #2cb7b0;
      padding: 8px;
      @media #{$max-m} {
        flex-wrap: wrap;
      }
    }

    .four-left {
      background: #5e6362;
      padding: 75px 85px 20px;
      flex: 0 0 50%;
      @media #{$max-m} {
        padding: 40px 20px;

        flex: 1 0 50%;
      }

      h3 {
        font-size: 2.65rem;
        font-weight: 600;
      }

      p {
        font-size: 1.35rem;
        line-height: 2.1rem;
        font-weight: 300;
        padding-bottom: 10px;
      }
    }

    .four-right {
      padding: 75px 85px 20px;
      background-color: #fff;
      flex: 0 0 50%;
      @media #{$max-m} {
        padding: 60px 20px 20px;
        flex: 1 0 50%;
      }

      img {
        float: right;
        width: 370px;
        margin-right: -20px;
        @media #{$max-m} {
          width: 280px;
        }
        @media #{$max-s} {
          float: none;
        }
      }
      h3 {
        font-size: 2.65rem;
        font-weight: 600;
        color: #2cb7b0;
        padding-bottom: 30px;
      }

      h4 {
        color: #514948;
        padding-top: 0;
      }

      p {
        color: #514948;
        padding-top: 0;
        font-size: 1.33rem;
        margin-top: 10px;
        font-weight: 300;
        line-height: 1.4;
      }
    }

    h3 {
      text-align: left;
      color: $white;
    }

    h4 {
      text-align: left;
      color: $white;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2rem;
      padding: 30px 0 15px;
    }

    p {
      text-align: left;
      color: $white;
    }
  }

  .about-4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #353131;
    padding: 77px 0 40px;
  }
  .about-4 .about-container {
    width: 53vw;
    @media #{$max-m} {
      width: 100%;
    }
  }
  .about-4 h3 {
    font-size: 2.6rem;
    font-weight: 800;
    color: #fff;
    padding-bottom: 45px;
  }

  .about-4 h4 {
    color: #53e0d2;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 2.8rem;
    padding-bottom: 25px;
  }

  .about-4 h5 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    padding-bottom: 40px;
  }

  .about-4 .text {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    @media #{$max-s} {
      flex-direction: column;
    }
  }

  .about-4 .text div:not(:last-child) {
    padding-right: 60px;
  }

  .about-4 .text div {
    width: 33%;
    @media #{$max-s} {
      width: 100%;
    }
  }

  .about-4 .text p {
    color: #fff;
    font-size: 1.06rem;
    line-height: 1.7rem;
    font-weight: 300;
    padding-bottom: 10px;
  }

  .text-footer {
    font-size: 1.1rem;
    color: #73cac2;
    font-weight: 500;
    padding: 53px 0 26px;
  }

  .text-footer span {
    font-size: 4rem;
    font-weight: 400;
    display: block;
    line-height: 1.5rem;
  }

  .about-5 {
    margin-top: 5px;
  }

  .about-5 div {
    background-size: cover;
    min-height: 20vw;
  }

  .about-5 div:first-child {
    width: 42%;
  }

  .about-5 div:nth-child(2) {
    width: 33%;
  }

  .about-5 div:nth-child(3) {
    width: 25%;
  }

  .about-5 div img {
    display: none;
  }

  .about-5 img {
    width: 100%;
    height: auto;
  }

  .about-5 div:not(:last-child) {
    margin-right: 5px;
  }

  .about-6 {
    padding: 60px 0;
    background: #ddf1ef;
    margin-top: 5px;
  }

  .about-6 .about-container {
    width: 70vw;
  }

  .about-6 h3 {
    font-size: 2.6rem;
    font-weight: 800;
    color: #73cac2;
    padding-bottom: 15px;
    text-align: center;
  }

  .about-6 .subtitle {
    color: #353131;
    line-height: 2.5rem;
  }

  .about-7 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 55px 0;
  }

  .about-7 .about-container {
    width: 70vw;
    @media #{$max-m} {
      width: 100%;
    }
  }

  .about-7 h3 {
    font-size: 2.6rem;
    font-weight: 800;
    color: #514948;
    padding-bottom: 50px;
    text-align: center;
  }

  .about-7 ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: space-between;
  }

  .about-7 .awardsList {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    @media #{$max-m} {
      gap: 1rem;
    }
    img {
      max-height: 5rem;
      height: 100%;
      @media #{$max-m} {
        max-height: 4rem;
      }
      @media #{$max-s} {
        max-width: 90%;
      }
    }
  }
}
