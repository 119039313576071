@import '../../../../styles/variables';

.articleAuthor {
  font-size: 0.9rem;
  color: #514948;
  font-weight: 500;
  padding-right: 1.875rem;
  margin-left: 5px;
  transition: color 0.5s $cubic-out;
  display: flex;
  align-items: center;
  gap: 0.2rem;

  line-height: 1.6;

  @media #{$max-m} {
    padding-right: 1rem;
  }
  .name {
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(80%);
    max-width: 89rem;
    overflow: hidden;
    line-height: 1.3;
    transition: color 0.5s $cubic-out;
    &:hover {
      color: #2eb7b0;
    }
  }
  &::before {
    content: '';
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'  viewBox='0 0 26.5 23.5'%3E%3Cpath style='fill:%2373CAC2;' d='M23.8,23.5H2.7c-1.5,0-2.7-1.2-2.7-2.7V2.7C0,1.2,1.2,0,2.7,0h14.6v4H4v15.5h18.5v-8.8h4v10.2 C26.5,22.3,25.3,23.5,23.8,23.5z'/%3E%3C/svg%3E")
      no-repeat;
    width: 1.6875rem;
    height: 100%;
    margin-right: 0.8rem;
    @media #{$max-m} {
      display: none;
    }
    @media #{$max-s} {
      display: unset;
    }
  }
}
