@import '../../styles/variables';

header {
  height: 6.25rem;
  background: $white;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  display: grid;
  margin-top: 6.25rem;
  transform: translateY(-6.25rem);
  transition: all 0.4s $cubic-out;
  z-index: 2;

  .divider {
    &::after,
    &::before {
      width: 50%;
    }
  }

  .container {
    justify-content: flex-start;
    align-content: center;
    width: 100%;
    align-items: center;
    position: relative;
    z-index: 2;
    @media #{$max-s} {
      justify-content: space-between;
    }
    .brand {
      display: flex;
      z-index: 3;
      width: 10.625rem;
      height: 2.625rem;
      transition: width 0.4s $cubic-out;
      @media #{$max-s} {
        order: 2;
      }
      svg {
        height: 100%;
        width: 100%;
        transition: all 0.3s $cubic;
        fill: $primary-1;
      }
      &:hover {
        svg {
          fill: $primary-2;
        }
      }
    }
    .mobile-nav {
      //margin-left: -63px;
      display: none;
      @media #{$max-s} {
        display: block;
        order: 1;
      }
    }

    .nav-icon {
      width: 30px;
      height: 22px;
      position: relative;
      transform: rotate(0);
      transition: 0.5s $cubic;
      cursor: pointer;
      z-index: 9999;
    }

    .nav-icon span {
      display: block;
      position: absolute;
      height: 4px;
      width: 100%;
      background: $primary-2;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0);
      transition: 0.25s $cubic-out;
    }

    .nav-icon span:nth-child(1) {
      top: 0;
    }

    .nav-icon span:nth-child(2),
    .nav-icon span:nth-child(3) {
      top: 8px;
    }

    .nav-icon span:nth-child(4) {
      top: 16px;
    }

    .nav-icon.navIsOpen span:nth-child(1) {
      top: 8px;
      width: 0%;
      left: 50%;
    }

    .nav-icon.navIsOpen span:nth-child(2) {
      transform: rotate(45deg);
    }

    .nav-icon.navIsOpen span:nth-child(3) {
      transform: rotate(-45deg);
    }

    .nav-icon.navIsOpen span:nth-child(4) {
      top: 8px;
      width: 0%;
      left: 50%;
    }
  }
  .menu {
    flex-grow: 1;
    @media #{$max-s} {
      display: none;
    }
    .menuItems {
      margin-left: 5rem;
      list-style: none;
      display: flex;
      .menuItem {
        font-size: $fs-4;
        color: $primary-3;
        font-weight: $light;
        box-sizing: border-box;
        margin-right: 1.36rem;
        transition: color 0.3s $cubic;
        &:hover {
          color: $primary-1;
        }
      }
    }
  }
  .language {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    @media #{$max-s} {
      display: none;
    }
    .dropdown {
      position: relative;
      display: inline-block;

      .language-select {
        color: $primary-3;
        font-size: $fs-1;
        font-weight: $semibold;
        &::after {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 11'%3E%3Cpath style='fill:%232CB7AF' d='M9,10.9c-0.3,0-0.6-0.1-0.7-0.3l-8-8.9c-0.4-0.4-0.3-1,0.1-1.4c0.4-0.4,1-0.3,1.4,0.1L9,8.4l7.2-8.1 c0.4-0.4,1-0.4,1.4-0.1c0.4,0.4,0.4,1,0.1,1.4l-8,8.9C9.5,10.8,9.3,10.9,9,10.9z'/%3E%3C/svg%3E")
            no-repeat;
          display: inline-block;
          width: 12px;
          height: 8px;
          content: '';
          margin-left: 3px;
        }
      }
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: $white;
        padding: 12px 16px;
        z-index: 1;
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
        right: 0;
        flex-direction: column;
        div {
          color: $primary-3;
          display: block;
          font-size: $fs-1;
          padding: 10px 20px 10px 10px;
          transition: color 0.3s $cubic;
          cursor: pointer;
          order: 1;
          &:hover {
            color: $primary-1;
          }
          &.isActive {
            order: 0;
            display: flex;
          }
        }
      }
    }
    .dropdown:hover .dropdown-content {
      display: flex;
    }
  }
}
.isCollapsed {
  position: fixed;
  opacity: 0.95;
  height: 5rem;
  z-index: 3;
  margin-top: 0;
  transform: translateY(0);
  .container {
    .brand {
      display: flex;
      z-index: 3;
      width: 8rem;
    }
  }
  @media #{$max-xs} {
    position: absolute;
  }
}

.mobile-menu {
  background: $secondary-8;
  position: absolute;
  top: -1px;
  left: 0;
  width: 0;
  z-index: 1003;
  padding: 90px 25px;
  transition: all 0.5s $cubic;
  transform: translateX(-350px);
  opacity: 0;
  color: $primary-3;
  a {
    transition: color 0.3s $cubic;
  }
  .menu-list {
    font-size: 1.3rem;
    font-weight: $light;
    ul {
      display: flex;
      flex-direction: column;
    }
    li {
      border-bottom: 1px solid $secondary-1;
    }

    a {
      line-height: 3.2rem;
    }
  }
  .lang-list {
    font-weight: $semibold;
    font-size: 0.9rem;
    text-transform: uppercase;
    margin-bottom: 2.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $secondary-1;
    display: flex;
    div:not(:first-child) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  ul {
    list-style: none;
  }
  a {
    color: $primary-3;
    &:hover {
      color: $secondary-1;
    }
  }
}
.navIsOpen {
  .mobile-menu {
    transform: translateX(0);
    min-width: 350px;
    opacity: 1;
    @media #{$max-xs} {
      min-width: 100%;
      height: 100vh;
    }
  }
}
