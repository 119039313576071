@import '../../../../styles/variables';

.article {
  background-color: $white;
  display: flex;
  flex-direction: column;
  &-image {
    position: relative;
    height: 13rem;
    width: 100%;
    overflow: hidden;
    img {
      opacity: 1;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      transition: transform 4s $cubic-out;
      animation-duration: 2s;
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  &-body {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
    &-title {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.6rem;
      color: #2eb7b0;
    }
    &-short {
      font-size: 1rem;
      color: #7c7777;
      line-height: 1.4rem;
      font-weight: 400;
    }
  }
  &-footer {
    padding: 1.8rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
