@import '../../../../styles/variables';

.featuredArticle {
  display: flex;
  flex-wrap: nowrap;
  padding: 35px 55px 35px 35px;
  background-color: $white;
  gap: 2rem;
  @media #{$max-m} {
    padding: 25px 35px 25px 25px;
  }
  @media #{$max-s} {
    flex-wrap: wrap;
  }
  &-content {
    display: flex;
    flex-direction: column;
  }
  &-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-title {
    font-size: 2rem;
    color: #73cac2;
    font-weight: 700;
    line-height: 2.1rem;
    transition: color 0.5s $cubic-out;
    &:hover {
      color: #a3a09d;
    }
    @media #{$max-m} {
      font-size: 1.6rem;
    }
  }
  &-image {
    max-width: 25rem;
    max-height: 25rem;
    min-width: 20rem;
    width: 100%;
    height: auto;
    animation-duration: 2s;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
    img {
      transition: transform 3s $cubic-out;
      width: 100%;
    }
    @media #{$max-m} {
      max-width: 20rem;
      max-height: 20rem;
      min-width: 15rem;
    }
    @media #{$max-s} {
      max-width: unset;
      width: 100%;
      height: auto;
      max-height: unset;
    }
  }
  &-short {
    font-size: 1.3rem;
    color: #7c7777;
    font-weight: 300;
    line-height: 1.8rem;
    @media #{$max-m} {
      font-size: 1.2rem;
    }
  }
  &-footer {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 0px;
    @media #{$max-s} {
      padding-top: 2rem;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }
    &-left {
      display: flex;
      flex-wrap: nowrap;
      gap: 4rem;
      @media #{$max-m} {
        gap: 2rem;
      }
      @media #{$max-s} {
        flex-wrap: wrap;
      }
    }
  }
}
