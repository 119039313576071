@import '../../../styles/variables';

.faqPage {
  background-color: #f9f7f7;
  display: flex;
  flex-grow: 1;
  .container {
    max-width: 1340px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 2rem;
  }
  &-title {
    background-color: #73cac2;
    font-weight: 800;
    font-size: 2.5rem;
    padding: 6.25rem 1rem 1.5rem 1rem;
    text-align: center;
    color: #fff;
    display: flex;
    width: 100%;
    justify-content: center;
  }
}
