@import '../../../../styles/variables';
.articleShare {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: #666;
  @media #{$max-m} {
    // gap: 1rem;
  }
  &-icons {
    display: flex;
    gap: 0.4rem;
    @media #{$max-m} {
      gap: 0.5rem;
    }
    &-item {
      transition: opacity 0.5s $cubic-out;
      &:hover {
        opacity: 0.6;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        @media #{$max-m} {
          width: 1.4rem;
          height: 1.4rem;
        }
      }
    }
  }
}
