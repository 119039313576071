@import '../../../styles/variables';

.page {
  &-header {
    height: 8.75rem;
    background-position-y: bottom;
    background-size: cover;
    display: flex;
    align-items: center;
    &-header {
      &-title {
        font-weight: $bold;
        color: $white;
        font-size: 4rem;
      }
    }
  }
}
