/**
  Reset
  ===================================================================
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font: inherit;
  color: inherit;
  outline: transparent;
  background: transparent;
  border-radius: 0;
  border: 0;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  line-height: 1;
  overflow: auto;
  &.in-transition {
    transition: transform 900ms ease;
  }
}
body.opened-modal {
  overflow: hidden;
}
ol[class],
ul[class] {
  list-style: none;
}

img,
svg {
  display: block;
}

button,
input,
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button,
input[type='submit'],
input[type='checkbox'],
input[type='radio'],
select,
label,
a {
  cursor: pointer;
}

button[disabled] {
  cursor: default;
}

textarea {
  resize: none;
}

a {
  text-decoration: none;
  touch-action: manipulation;
}
