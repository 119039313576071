@import '../../../styles/variables';

.termsPage {
  background-color: #f7f7f7;
  .container {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 1400px;
  }
  &-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    background: #73cac2;
    .container {
      max-width: 1100px;
      padding: 6.25rem 2rem 1.5rem;
    }
    &-title {
      max-width: 1300px;
      color: #fff;
      font-size: 2.5rem;

      font-weight: 800;
      padding-bottom: 10px;
    }
  }
  &-text {
    background-color: #fff;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #7c7777;

    .container {
      max-width: 1100px;
      padding: 4.25rem 2rem 5rem;
    }
    p {
      padding-top: 15px;
    }
    h1 {
      font-size: 1.8rem;
      font-weight: 600;
      padding-top: 20px;
      color: #2cb7b0;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      padding: 20px 0 10px;
    }
    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      padding: 20px 0 10px;
      text-decoration: underline;
    }
    strong {
      font-weight: 800;
    }
    a {
      color: #73cac2;
      transition: color 0.4s $cubic-out;
      &:hover {
        color: #2f455c;
      }
    }
  }

  li {
    margin-left: 40px;
    padding-top: 5px;
  }
  li,
  p {
    font-size: 1.2rem;
    padding-top: 15px;
    line-height: 1.6rem;
    color: #7c7777;
  }
}
