@import '../../styles/variables';

.layout {
  min-height: 100vh;
  max-width: 100vw;
  width: 100%;
  display: flex;
  flex-direction: column;
  // &.isDark {
  //   background-color: $black;
  // }
  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: calc(100vh - 6rem);
    height: 100%;
    transition: all 1s $cubic-out;
  }
  &.expandFooter {
    .main {
      flex-shrink: 1;
      flex-grow: 0;
      min-height: 0;
      max-height: 0;
      height: 0;
    }
    footer {
      flex-grow: 1;
      height: 100%;
      .contactMap {
        max-height: 28.125rem;
        height: 100%;
        overflow: hidden;
      }
      .footer-1 {
        min-height: 100%;
        flex-grow: 1;
        padding-top: 4rem;
        padding-bottom: 4rem;
      }
      .contactText {
        max-height: 10rem;
        opacity: 1;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
}
