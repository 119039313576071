@import '../../../styles/variables';

.tech-hero {
  background-color: $primary-5;
  padding-bottom: 2rem;
  text-align: center;
  .tech-hero-title {
    font-size: $fs-7;
    font-weight: $extrabold;
    color: $white;
    line-height: 1.4;
    background: $primary-3;
    width: 100%;
    padding: 1.2rem 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.125rem;
    .tech-hero-subtitle {
      font-size: $fs-6;
      font-weight: $regular;
      color: $primary-3;
      line-height: 1.3;
      max-width: 43.75rem;
    }
    .tech-hero-hero-image {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 2rem;
      animation-duration: 3s;
      max-height: 35.625rem;
      img {
        max-width: 62.5rem;
        width: 100%;
      }
    }
    .tech-hero-text {
      font-size: $fs-6;
      font-weight: $light;
      color: $primary-3;
      line-height: 1.4;
      max-width: 68rem;
    }

    .boxes-tech {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: left;
      .box {
        flex: 1 1 350px;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        .title {
          font-size: 2rem;
          color: $secondary-4;
          font-weight: $semibold;
          line-height: 1.4;
          padding-block: 1rem;
        }
        .text {
          font-size: 1.1rem;
          color: $black;
          font-weight: $light;
          line-height: 1.3;
          flex-grow: 1;
        }
      }
    }
  }

  .tech-hero-button {
    cursor: pointer;
    width: fit-content;
    margin: 1.5rem 0;
    font-size: 1.1rem;
    font-weight: $semibold;
  }
}

.teasers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 7rem 20px;
  @media #{$max-s} {
    padding: 3rem 20px;
  }
  &.container {
    @media #{$max-m} {
      flex-wrap: wrap;
    }
  }
}

.teaser {
  flex: 1 1 22%;
  position: relative;
  z-index: 1;
  margin: 1rem;
  display: flex;
  flex-direction: column;

  @media #{$max-m} {
    flex: 1 1 40%;
  }
  @media #{$max-s} {
    flex: 1 1 100%;
    margin: 0;
  }
}

.teaser-top {
  position: relative;
  .teaser-image {
    background-size: cover;
    min-height: 22rem;
    background-position: center;
    @media #{$max-xl} {
      min-height: 19rem;
    }
    @media #{$max-ml} {
      min-height: 15rem;
    }
    img {
      width: 100%;
      height: auto;
      opacity: 0;
    }
  }
  .teaser-title {
    position: absolute;
    top: 1.4rem;
    left: 1.4rem;
    font-size: 4rem;
    color: #fff;
    width: min-content;
    line-height: 1;
    text-transform: uppercase;
    font-weight: $bold;
    @media #{$max-ml} {
      font-size: 3.5rem;
    }
    @media #{$max-m} {
      font-size: 3rem;
    }
    sup {
      font-size: 2.938rem;
    }
  }
}

.teaser-content {
  z-index: 2;
  position: relative;
  background: #fff;
  padding: 2rem 1.2rem;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  .teaser-texts {
    padding-bottom: 1.5rem;
    flex-grow: 1;

    .teaser-text {
      line-height: 1.5;
      display: flex;
      span {
        font-weight: $medium;
        min-width: 110px;
      }
      .description {
        font-weight: $light;
        font-size: 0.95rem;
      }
    }
  }
  .teaser-button {
    cursor: pointer;
    width: fit-content;
  }
}
