@import '../../../../styles/variables';

.coolOneFaq {
  background: #f9f7f7;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.3rem;
  &-title {
    text-align: center;
    font-size: 2rem;
    font-weight: 800;
    color: #2f455c;
    line-height: 1.4;
  }
  &-button {
    margin-top: 3.125rem;
    width: fit-content;
    cursor: pointer;
  }
}
