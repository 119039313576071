@import '../../../../styles/variables';

.features {
  background-color: #f9f7f7;
  padding-bottom: 4rem;
  .container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 50px auto 0;
    max-width: 100%;

    @media #{$max-m} {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    @media #{$max-s} {
      padding: 0;
    }
  }
  h2 {
    font-size: 2.5rem;
    color: #2f455c;
    font-weight: 600;
    text-align: center;
    padding: 55px 0;
    b,
    strong {
      color: #73cac2;
    }
  }
}
.left-image {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media #{$max-l} {
    margin-right: 4%;
  }
  @media #{$max-m} {
    margin-right: 10%;
    justify-content: center;
    margin-bottom: -70px;
  }
  @media #{$max-s} {
  }
}
.circle {
  width: 55%;
  @media #{$max-m} {
    width: 100%;
  }
}
.circle-content {
  width: 40vw;
  height: 40vw;
  background: #2b2b2b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 490px;
  min-height: 490px;

  @media #{$max-l} {
    border-radius: 0;
    width: 50vw;
    height: fit-content;
    padding: 20px 0;
  }
  @media #{$max-m} {
    width: unset;
    min-height: auto;
    min-width: auto;
    border-radius: 0;
  }

  ul {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    padding: 50px;
    list-style: none;
    @media #{$max-m} {
      padding: 20px;
    }
    li {
      margin-bottom: 12px;
    }
  }
}
.textList {
  max-width: 26.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media #{$max-l} {
    max-width: fit-content;
    padding: 0 30px;
  }
  .line-style-1 {
    line-height: 1.2;
    font-size: 1.3rem;
    color: $white;
    font-weight: 500;
    padding-bottom: 1rem;
    span {
      font-size: 1.2rem;
    }
  }
  .line-style-2 {
    font-size: 1.1rem;
    line-height: 1.2;
    font-weight: 400;
    color: #73cac2;
  }
}
.circle-outer {
  border: 1vw solid #73cac2;
  width: max-content;
  padding: 10px;
  border-radius: 50%;
  margin-left: -5%;
  @media #{$max-l} {
    border-radius: 0;
  }
  @media #{$max-m} {
    border-radius: 0;
    width: fit-content;
    margin-left: unset;
  }
}
.cool-image {
  max-width: 810px;
  position: relative;
  right: -6.25rem;
  top: 15%;
  @media #{$max-s} {
    right: -30px;
    top: -20px;
  }
  img {
    z-index: 1;
    position: relative;
    width: 36vw;
    min-width: 450px;
    @media #{$max-s} {
      min-width: 340px;
      width: 100%;
    }
    @media #{$max-xs} {
      min-width: 300px;
      width: 100%;
    }
  }
}
.info-circles {
  position: absolute;
}
.info {
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  .left {
    height: 86px;
    width: 43px;
    background: #514948;
    border-top: 4px solid #73cac2;
    border-bottom: 4px solid #73cac2;
    position: relative;
    z-index: 2;
    border-left: 4px solid #73cac2;
    border-bottom-left-radius: 86px;
    border-top-left-radius: 86px;
  }
  .right {
    height: 86px;
    width: 43px;
    background: #514948;
    border-top: 4px solid #73cac2;
    border-bottom: 4px solid #73cac2;
    position: relative;
    z-index: 2;
    border-right: 4px solid #73cac2;
    border-bottom-right-radius: 86px;
    border-top-right-radius: 86px;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 92px;
    height: 92px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 38' width='16' height='38'%3E%3Cpath style='fill:%23FFFFFF;' d='M16,37.8H0.6V36c0.4,0,0.8-0.1,1.2-0.1c0.4,0,0.8-0.1,1-0.2c0.5-0.2,0.9-0.5,1.1-0.8 c0.2-0.4,0.3-0.9,0.3-1.5V19.1c0-0.6-0.1-1.1-0.4-1.5c-0.3-0.4-0.6-0.8-1-1c-0.3-0.2-0.7-0.4-1.3-0.6C1,15.9,0.5,15.7,0,15.7v-1.8 l12-0.6l0.4,0.4V33c0,0.6,0.1,1.1,0.4,1.5c0.2,0.4,0.6,0.7,1,0.9c0.3,0.1,0.7,0.3,1.1,0.4c0.4,0.1,0.8,0.2,1.2,0.2V37.8z M12.5,4.5 c0,1.2-0.5,2.3-1.4,3.2C10.1,8.5,9,9,7.7,9C6.3,9,5.2,8.5,4.3,7.7c-1-0.9-1.4-1.9-1.4-3.2c0-1.2,0.5-2.3,1.4-3.2S6.3,0,7.7,0 s2.4,0.4,3.4,1.3C12,2.2,12.5,3.3,12.5,4.5z'/%3E%3C/svg%3E%0A");
    z-index: 3;
    background-repeat: no-repeat;
    background-position: 36px center;
  }
  &:hover {
    .circle-info-content {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      p {
        opacity: 1;
        display: block;
      }
    }
  }
}
.circle-info-content {
  width: 0;
  border-top: 4px solid #73cac2;
  border-bottom: 4px solid #73cac2;
  background: #514948;
  position: relative;
  z-index: 2;
  transition: width 0.3s ease-in-out;
  p {
    opacity: 0;
    display: none;
    color: $white;
    min-width: 180px;
    padding-left: 25px;
    font-size: 0.9rem;
    line-height: 1.5;
    animation: fadeIn ease 0.5s;
  }
}
.circle-1 {
  position: absolute;
  left: 9vw;
  top: -7vw;
  @media #{$max-m} {
    left: 14vw;
  }
  @media #{$max-s} {
    left: 21vw;
    top: -14vw;
  }
  .line {
    display: block;
    width: 2px;
    height: 24vw;
    background: #7c7777;
    top: 3vw;
    position: absolute;
    left: 47px;
    z-index: 0;
    transform: rotate(0);
  }
}
.circle-1.info {
  &:hover {
    .circle-info-content {
      width: 190px;
    }
  }
}
.circle-2.info {
  &:hover {
    .circle-info-content {
      width: 135px;
    }
  }
}
.circle-3.info {
  &:hover {
    .circle-info-content {
      width: 230px;
    }
  }
}
.circle-2 {
  position: absolute;
  left: -7vw;
  top: 15vw;
  @media #{$max-s} {
    left: -13vw;
  }
  .line {
    display: block;
    width: 20vw;
    height: 2px;
    background: #7c7777;
    top: 50%;
    position: absolute;
    left: 0;
    z-index: 0;
  }
}
.circle-3 {
  position: absolute;
  --max-line-height: -80;
  --min-line-height: -60;
  left: var(--responsive-line-height);
  top: -1vw;
  .line {
    display: block;
    width: 2px;
    height: 28vw;
    background: #7c7777;
    top: 0;
    position: absolute;
    left: 13.5vw;
    z-index: 0;
    transform: rotate(-44deg);
  }
}
