@import '../../../styles/variables';

.communityPage {
  #information-community.cnt {
    background: #fff;
  }

  #information-community .info-title {
    background: #73cac2;
    padding: 40px 0 12vw;
    @media #{$max-m} {
      padding: 0 20px 60px;
    }
  }

  #information-community .info-title h1 {
    text-align: center;
    padding: 17px;
    font-size: 2.6rem;
    font-weight: 600;
    color: #fff;
  }

  .community-image {
    display: flex;
    justify-content: center;
    margin-top: -10vw;
    padding: 0;
  }

  .community-image img {
    padding: 12px;
    background: #fff;
    @media #{$max-m} {
      width: 100%;
      padding: 0;
    }
  }

  #information-community .info-main-container {
    padding-top: 0;
    text-align: center;
    @media #{$max-m} {
      padding: 0 2rem;
    }
    @media #{$max-s} {
      padding: 0 1rem;
    }
  }

  #information-community .contact-info-cont {
    width: 100%;
  }

  #information-community .text-1 {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  #information-community .text-2 {
    color: #2cb7b0;
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 20px;
  }

  #information-community .text-3 {
    color: #2f455c;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .col-30 {
    width: 28%;
  }

  .col-30:nth-child(2) {
    margin: 0 2%;
  }

  #information-community ::placeholder {
    color: #2eb7b0;
    font-size: 0.9rem;
    font-weight: 800;
    text-transform: uppercase;
  }

  #information-community .contact-fields {
    justify-content: center;
  }

  #information-community .text-4 {
    font-size: 1.1rem;
    font-weight: 600;
    padding: 20px 0 0;
    text-align: center;
  }

  #information-community .contact-details {
    max-width: 750px;
  }

  #information-community .info-title .info-content {
    align-self: center;
    display: flex;
    justify-content: center;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    max-width: 580px;
    font-size: 1.2rem;
    padding-left: 20px;
    padding-right: 20px;
  }

  #information-community h3 {
    color: #2cb7b0;
    font-size: 2rem;
    padding-bottom: 30px;
  }

  .main-info {
    background: #fff;
    width: 100%;
    margin: 0 auto;
    padding: 45px 0 35px;
    display: block;
  }

  .contact-info-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }

  .contact-image {
    margin-right: 50px;
  }

  .contact-details {
    color: #7c7777;
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 300;
  }

  .contact-details h3 {
    width: 100%;
    color: #73cac2;
    font-weight: 800;
    font-size: 1.7rem;
    padding-bottom: 40px;
  }

  .contact-infos {
    display: flex;
    flex-wrap: nowrap;
  }

  .contact-info-left {
    width: 50%;
  }

  .contact-email strong,
  .contact-info-right a {
    color: #2cb7b0;
  }

  .contact-email a:hover {
    color: #2f455c;
  }

  .contact-info-right {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 50%;
  }

  .contact-social {
    font-size: 1.1rem;
    font-weight: 400;
    margin-top: 10px;
  }

  .bulletList li {
    padding-left: 15px;
    padding-right: 30px;
    --max-margin: 17;
    --min-margin: 12;
    margin-bottom: var(--responsive-margin);
    font-weight: 400;
    text-align: left;
    @media #{$max-s} {
      padding-left: 32px;
      font-size: 1.3rem;
    }
  }

  .bulletList li::before {
    content: ' ';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.6 23' %3E%3Cpath style='fill:%232AB6AF;' d='M11.8,23C5.3,23,0,17.8,0,11.5S5.3,0,11.8,0c6.5,0,11.8,5.2,11.8,11.5S18.3,23,11.8,23z M11.8,2 C6.4,2,2,6.3,2,11.5S6.4,21,11.8,21c5.4,0,9.8-4.3,9.8-9.5S17.2,2,11.8,2z'/%3E%3Cpath style='fill:%232AB6AF;' d='M11.4,17.1c-0.2,0-0.4-0.1-0.6-0.2l-3.7-2.7c-0.4-0.3-0.5-1-0.2-1.4c0.3-0.4,1-0.5,1.4-0.2l2.8,2 l3.6-7.4c0.2-0.5,0.8-0.7,1.3-0.5c0.5,0.2,0.7,0.8,0.5,1.3l-4.2,8.5c-0.1,0.3-0.4,0.5-0.7,0.5C11.6,17.1,11.5,17.1,11.4,17.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: block;
    margin-left: -35px;
    position: absolute;
    margin-top: 4px;
  }

  .info-form form {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 0 55px;
  }

  .form-header {
    margin-bottom: 20px;
  }

  .info-form h3,
  .info-form h4 {
    text-align: center;
    padding-bottom: 10px;
  }

  .info-form h3 {
    color: #5b514b;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .info-form h4 {
    font-size: 1rem;
    font-weight: 300;
    color: #2cb7b0;
  }

  .info-form label {
    font-size: 0.9rem;
    font-weight: 600;
    color: #2cb7b0;
    text-transform: uppercase;
  }

  .info-form label span {
    font-weight: 300;
    font-size: 1.2rem;
    padding-left: 10px;
  }

  .info-form input,
  .info-form select,
  .info-form textarea {
    font-size: 1.3rem;
    font-weight: 300;
    color: #514948;
    border-bottom: 1px solid #d5d1cb;
    padding: 9px 0;
    border-top: none;
    border-left: none;
    border-right: none;
    background: 0 0;
    outline: 0;
    width: 100%;
  }

  .info-form select {
    margin-top: 10px;
    border: 1px solid #d5d1cb;
    padding-left: 20px;
  }

  .info-form input[type='radio'] {
    display: inline;
    width: 30px;
  }

  .orders a.add-btn {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .orders .add-btn span {
    display: inline;
    padding-left: 20px;
  }

  .contact-fields {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  #information-community .text-4 {
    font-size: 1.1rem;
    font-weight: 600;
    padding: 20px 0 0;
    text-align: center;
    color: #666;
  }
  .communityForm-success {
    padding: 20px 0 100px;
    text-align: center;
    font-weight: 600;
    color: #73cac0;
    font-size: 1.5rem;
  }
  .error-message {
    padding: 0.5rem 0;
    font-size: 0.9rem;
    color: red;
  }
}
