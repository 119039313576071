@import '../../../styles/variables';

.markets {
  background-color: $secondary-2;
  padding-bottom: 2rem;
  text-align: center;
  .markets-title {
    font-size: $fs-7;
    font-weight: $extrabold;
    color: $white;
    line-height: 1.4;
    background: $primary-3;
    width: 100%;
    padding: 1.2rem 0;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3.125rem;
    @media #{$max-s} {
      padding-left: 0;
      padding-right: 0;
    }
    .markets-text {
      font-size: $fs-6;
      font-weight: $light;
      color: $primary-3;
      line-height: 1.4;
      max-width: 68rem;
    }

    .market-boxes {
      padding: 3.5rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      z-index: 0;
      @media #{$max-s} {
        padding: 1rem;
      }

      @media #{$max-xs} {
        padding: 0;
      }
      .market-box {
        position: relative;
        display: inline-block;
        max-width: 100%;
        height: auto;
        flex: 0 1 700px;
        min-height: 45rem;
        // overflow: hidden;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin: 2rem 1rem;
        animation-duration: 2s;
        @media #{$max-xl} {
          flex: 0 1 500px;
        }
        @media #{$max-ml} {
          min-height: 35rem;
        }
        @media #{$max-m} {
          flex: 0 1 500px;
          min-height: unset;
          height: 30rem;
        }
        @media #{$max-s} {
          flex: 0 1 100%;
        }
        img {
          max-width: 100%;
          width: 100%;
          opacity: 0;
        }
        .market-box-layer_bottom {
          display: block;
        }
        .market-box-layer_top {
          position: absolute;
          top: 60%;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: #fff;
          color: $primary-3;
          padding: 2.5rem 2rem;
          transition: all 0.6s $cubic;
          display: flex;

          @media #{$max-ml} {
            padding: 1.5rem 1.5rem;
          }

          .market-box-text {
            text-align: left;
            display: grid;
            grid-template-rows: auto 1fr auto;
            .title {
              font-size: 2.813rem;
              font-weight: $semibold;
              line-height: 1.1;
              padding-bottom: 2.8rem;
              padding-top: 2rem;
              text-align: center;
              text-transform: uppercase;
              @media #{$max-xl} {
                font-size: 2.5rem;
                padding-bottom: 2rem;
              }
              @media #{$max-ml} {
                font-size: 2.3rem;
                padding-bottom: 1.5rem;
                padding-top: 1rem;
              }
              @media #{$max-s} {
                font-size: 1.4rem;
                padding-bottom: 1rem;
                padding-top: 0rem;
              }
            }
            .text {
              font-size: 1.313rem;
              line-height: 1.3;
              transform: translateY(100%);
              transition: transform 0.7s $cubic;
              text-align: center;
              font-weight: $light;
              @media #{$max-xl} {
                font-size: 1.2rem;
              }
              @media #{$max-ml} {
                font-size: 1rem;
              }
              p {
                padding-bottom: 0.5rem;
              }
              .marketbox-button {
                margin-top: 2rem;
                font-size: 0.938rem;
                font-weight: $medium;
              }
            }
          }
          .market-box-text_mobile {
          }
        }
        .market-box-layer_slideleft {
          transform: translateY(12%);
          @media #{$max-xl} {
            transform: translateY(7%);
          }
          @media #{$max-ml} {
            transform: translateY(0);
          }
        }
      }
      .market-box:hover .market-box-layer_top,
      .market-box.active .market-box-layer_top {
        opacity: 1;
      }
      .market-box.active .market-box-text_mobile {
        display: block;
      }
      .market-box:hover .market-box-layer_slideleft,
      .market-box.active .market-box-layer_slideleft {
        transform: translateY(-30%);
        @media #{$max-m} {
          transform: translateY(-45%);
        }
      }
      .market-box:hover .market-box-text .text {
        transform: translateY(0);
      }
      .marketbox-button {
        .btn {
          cursor: pointer;
          display: inline-block;
        }
      }
      .market-box-content {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .divider::after,
      .divider::before {
        box-shadow: 0 7px 30px rgba(0, 0, 0, 0.25);
      }
    }
  }
}
